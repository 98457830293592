<template>
  <component
    :is="tag"
    :class="[
      'link',
      themeClass,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'link--disabled' : null,
    ]"
    :href="external ? url : undefined"
    :to="!external ? url : undefined"
    :target="link.target"
    @[event]="data.fn"
    v-html="title"
  />
</template>

<script>
export default {
  name: 'Link',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    event() {
      return this.data.fn ? 'click' : null;
    },
    tag() {
      return this.data.fn ? 'button' : this.external ? 'a' : 'router-link';
    },
    link() {
      return this.data.value ? this.data.value.link : this.data;
    },
    title() {
      return this.link.title;
    },
    url() {
      if (
        this.link.url
        && typeof this.link.url === 'string'
      ) {
        return this.$relativeUrl(this.link.url);
      }
      return this.link.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    themeClass() {
      return this.theme ? `link--${this.theme}` : this.settings.theme ? `link--${this.settings.theme}` : false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;
  &.router-link-active {
    // style
  }
}

.link--disabled {
  opacity: 0.5;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}

.link {
  &--button {
    border-radius: var(--border-radius);
    background: var(--black);
    color: var(--white);
    padding: var(--spacer-s) var(--spacer-m);
    text-align: center;
  }
}
</style>
